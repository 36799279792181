@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }
    @media (max-width: 640px) {
        .mobile-safe-top {
            padding-top: env(safe-area-inset-top);
        }
    }
  }

@layer base {
    html {
      font-family: "GT Flexa", system-ui, sans-serif;
    }
  }

#root{
    max-width: 1400px;
    margin: 0 auto;
}

@font-face {
    font-family: 'GT Flexa';
    src: url('assets/fonts/GT-Flexa/GT-Flexa-Standard-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Flexa';
    src: url('assets/fonts/GT-Flexa/GT-Flexa-Standard-Bold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GT Flexa';
    src: url('assets/fonts/GT-Flexa/GT-Flexa-Standard-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Flexa';
    src: url('assets/fonts/GT-Flexa/GT-Flexa-Standard-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }